export default {
  props: {
    fillWidth: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: [String, Number],
      default: 0
    }
  },
  methods: {
    clicked (ev) {
      this.$emit('click', ev)
    }
  }
}
