<template>
  <transition
    name="pulse_animation"
    >
    <slot></slot>
  </transition>
</template>

<script>

export default {
  name: 'HeartBeat'
}
</script>

<style scoped>
@keyframes pulse_animation {
    0% { transform: scale(1); }
    20% { transform: scale(1); }
    30% { transform: scale(1.08); }
    40% { transform: scale(1); }
    45% { transform: scale(1.05); }
    50% { transform: scale(1); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}
@-webkit-keyframes pulse_animation {
    0% { -webkit-transform: scale(1); }
    20% { -webkit-transform: scale(1); }
    30% { -webkit-transform: scale(1.08); }
    40% { -webkit-transform: scale(1); }
    45% { -webkit-transform: scale(1.05); }
    50% { -webkit-transform: scale(1); }
    80% { -webkit-transform: scale(1); }
    100% { -webkit-transform: scale(1); }
}
@-moz-keyframes pulse_animation {
    0% { -moz-transform: scale(1); }
    20% { -moz-transform: scale(1); }
    30% { -moz-transform: scale(1.08); }
    40% { -moz-transform: scale(1); }
    45% { -moz-transform: scale(1.05); }
    50% { -moz-transform: scale(1); }
    80% { -moz-transform: scale(1); }
    100% { -moz-transform: scale(1); }
}
@-o-keyframes pulse_animation {
    0% { -o-transform: scale(1); }
    20% { -o-transform: scale(1); }
    30% { -o-transform: scale(1.08); }
    40% { -o-transform: scale(1); }
    45% { -o-transform: scale(1.05); }
    50% { -o-transform: scale(1); }
    80% { -o-transform: scale(1); }
    100% { -o-transform: scale(1); }
}

.animated {
    animation-name: pulse_animation;
    animation-duration: 1800ms;
    transform-origin: 70% 70%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: pulse_animation;
    -webkit-animation-duration: 1800ms;
    -webkit-transform-origin: 70% 70%;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: pulse_animation;
    -moz-animation-duration: 1800ms;
    -moz-transform-origin: 70% 70%;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -o-animation-name: pulse_animation;
    -o-animation-duration: 1800ms;
    -o-transform-origin: 70% 70%;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}
</style>
